function addListeners(elements, handler) {
  Array.from(elements).forEach(element => {
    element.addEventListener('click', handler);
  });
}

function eyeIconHandler() {
  this.style.display = 'none';
  this.nextElementSibling.style.display = '';
  this.previousElementSibling.type = 'text';
}

function slashedEyeIconHandler() {
  this.style.display = 'none';
  this.previousElementSibling.style.display = '';
  this.previousElementSibling.previousElementSibling.type = 'password';
}

document.addEventListener('turbolinks:load', () => {
  addListeners(document.getElementsByClassName('eye-icon'), eyeIconHandler);
  addListeners(document.getElementsByClassName('slashed-eye-icon'), slashedEyeIconHandler);
});
